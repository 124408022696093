import Head from 'next/head';
import { useRouter } from 'next/router';

import i18nConfig from '@/next-i18next.config';

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Button } from 'ui/button/button';
import Logo from 'ui/logo/logo';

import styles from 'app/styles/404.module.scss';

export default function ErrorPage() {
  const { t } = useTranslation('notfound');
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Error 404</title>
      </Head>
      <div className={styles.error_404}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.title}>404</h1>
            <span className={styles.description}>{t('sorry')}</span>
            <div className={styles.btn}>
              <Button fullWidth size='l' onClick={() => router.push('/')}>
                {t('Go to Homepage')}
              </Button>
            </div>
          </div>
          <div className={styles.footer}>
            <Logo />
          </div>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(
        locale,
        ['main', 'common', 'notfound'],
        i18nConfig
      )),
      // Will be passed to the page component as props
    },
  };
}
